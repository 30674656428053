<template>
  <div class="toppic">
    <div class="am-container-1">
      <div class="toppic-title left">
        <i class="am-icon-hand-paper-o toppic-title-i"></i>
        <span class="toppic-title-span">服务中心</span>
        <p>Service Center</p>
      </div>
      <div class="right toppic-progress">
        <span><router-link :to="{ path: '/', query: { id: 0 } }" class="w-white">首页</router-link></span>
        <i class="am-icon-arrow-circle-right w-white"></i>
        <span
          ><router-link :to="{ path: '/ServiceCenter', query: { id: 4 } }" class="w-white"
            >服务中心</router-link
          ></span
        >
      </div>
    </div>
  </div>

  <div class="am-container-1">
    <div class="service-center">
      <ul class="service-ul">
        <li class="am-u-lg-12 am-u-md-12 am-u-sm-12">
          <div class="am-u-lg-8 am-u-md-8 am-u-sm-12 service-content">
            <h4 class="w-blue">Android应用程序开发</h4>
            <p>
              据统计，2013年，Android全球市场份额达到了78.6%，而中国市场也达到了78%。在移动操作市场，Android已经成为不可动摇的王者。
              针对目前Android平台日益严重的碎片化问题，我们提供了多种解决方案，针对不同的系统版本，设备型号和设备分辨率，我们提供了全面
              的兼容性测试。同时，我们为客户的App提供了多平台发布服务，包括Google
              play，亚马逊应用商店，91，应用汇，机锋网等等多个平台，
              努力让更多的用户可以下载到。
            </p>
          </div>
          <div class="am-u-lg-4 am-u-md-4 am-u-sm-12 service-img">
            <img src="../assets/android.png" />
          </div>
        </li>
        <li class="am-u-lg-12 am-u-md-12 am-u-sm-12">
          <div class="am-u-lg-8 am-u-md-8 am-u-sm-12 service-content">
            <h4 class="w-blue">IOS(iPhone、iPad、Mac os)应用程序开发</h4>
            <p>
              虽然Android的市场份额是绝对的王者，但是在高端手机市场领域，IOS同样成为市场的主导者。据统计，2013年，IOS全球市场份额达到
              了17.6%。虽然Android市场份额要比IOS要高，在细分领域，比如教育市场、企业市场、高端市场等，IOS是绝对的王者。我们服务的客户
              超过30家，他们来自不同的行业，包括房地产，服务，医疗等等。迄今为止已为客户开发了超过30款ios应用程序，其中有10款是iPad应用
              程序，目前上架运营的超过20款。我们紧随时代潮流，将最新的设计理念和技术应用到项目中。
            </p>
          </div>
          <div class="am-u-lg-4 am-u-md-4 am-u-sm-12 service-img">
            <img src="../assets/ios.png" />
          </div>
        </li>
        <li class="am-u-lg-12 am-u-md-12 am-u-sm-12">
          <div class="am-u-lg-8 am-u-md-8 am-u-sm-12 service-content">
            <h4 class="w-blue">微信公众平台开发</h4>
            <p>
              具腾讯官方统计，目前微信月活跃用户达到了3亿，其中海外用户更是达到了4千万。随着微信用户的不断增加，腾讯对其微信产品：公众平
              台帐号的不断投入与推广，对于企业客户来说，拥有一款微信公众平台产品比拥有一个企业官方网站更重要。我们不但致力于高品质App的开
              发，而且在微信公众平台二次开发方面拥有优秀开发人才和市场推广经验。迄今为止，我们已经为数十家企业开发了微信公众平台帐号。客户
              可以结合微信公众平台，更好得去运营App平台和网站，不但提高了用户的数量，而且更好得拉近了与用户的距离。
            </p>
          </div>
          <div class="am-u-lg-4 am-u-md-4 am-u-sm-12 service-img">
            <img src="../assets/wechat.png" />
          </div>
        </li>
        <li class="am-u-lg-12 am-u-md-12 am-u-sm-12">
          <div class="am-u-lg-8 am-u-md-8 am-u-sm-12 service-content">
            <h4 class="w-blue">服务器接口开发</h4>
            <p>
              无论ios平台、android平台、windows
              phone平台还是微信公众帐号平台，要想保持程序运行的稳定性、即时性，必须要有一个优良的服
              务器平台作为支撑。我们为客户提供了全球领先的服务器开发语言PHP和JAVA。根据客户的不同需求选择不同的开发语言，我们的服务器接
              口性能优良，支持十万百万级的用户并发量。
            </p>
          </div>
          <div class="am-u-lg-4 am-u-md-4 am-u-sm-12 service-img">
            <img src="../assets/webservice.png" />
          </div>
        </li>
        <li class="am-u-lg-12 am-u-md-12 am-u-sm-12">
          <div class="am-u-lg-8 am-u-md-8 am-u-sm-12 service-content">
            <h4 class="w-blue">企业网站开发</h4>
            <p>
              作为企业形象的门面，一个好的企业网站为客户的产品。武汉恒望不断致力于移动互联网产品的开发服务，更注重企业网站。
              我们不但致力于高品质App的开发，而且在微信公众平台二次开发方面拥有优秀开发人才和市场推广经验。迄今为止，我们已经为数十家企业
              开发了微信公众平台帐号。客户可以结合微信公众平台，更好得去运营App平台和网站，不但提高了用户的数量，而且更好得拉近了与用户的距离。
            </p>
          </div>
          <div class="am-u-lg-4 am-u-md-4 am-u-sm-12 service-img">
            <img src="../assets/website.png" />
          </div>
        </li>
        <li class="am-u-lg-12 am-u-md-12 am-u-sm-12">
          <div class="am-u-lg-8 am-u-md-8 am-u-sm-12 service-content">
            <h4 class="w-blue">手机移动网站开发</h4>
            <p>
              随着智能手机用户数量的爆发式增长，企业在拥有一个桌面版的网站的同时，更需要拥有一个适配智能手机的移动版网站。事实证明，拥有移
              动版的网站，可以让您的网站在移动版百度搜索结果里面拥有更好的排名。我们为客户提供了基于HTML5+CSS3+JQuery
              Mobile的移动版网站开
              发前端技术，使客户的网站在多种平台多种分辨率下拥有统一的显示效果，另外可以实现更炫的动态效果。
            </p>
          </div>
          <div class="am-u-lg-4 am-u-md-4 am-u-sm-12 service-img">
            <img src="../assets/mwebsite.png" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
</script>