<template>
  <div class="toppic">
    <div class="am-container-1">
      <div class="toppic-title left">
        <i class="am-icon-lightbulb-o toppic-title-i"></i>
        <span class="toppic-title-span">解决方案</span>
        <p>Solutions</p>
      </div>
      <div class="right toppic-progress">
        <span><router-link :to="{ path: '/', query: { id: 0 } }" class="w-white">首页</router-link></span>
        <i class="am-icon-arrow-circle-right w-white"></i>
        <span
          ><router-link :to="{ path: '/Solutions', query: { id: 1 } }" class="w-white"
            >解决方案</router-link
          ></span
        >
      </div>
    </div>
  </div>

  <div data-am-widget="tabs" class="am-tabs am-tabs-d2">
    <ul class="am-tabs-nav am-cf solutions-tabs-ul" style="display: flex;flex-wrap: wrap;">
      <li
        class="solutions-tabs-ul-li"
        v-for="(item, index) in tabList"
        :key="index"
        :class="{ 'am-active': item.id == currentIndex }"
        @click="tabChange(item.id)"
       >
        <a><i :class="item.icon"></i>{{ item.name }}</a>
      </li>
    </ul>
    <div class="am-tabs-bd solutions-tabs-content">
      <div
        v-if="tabPanel == 1"
        :class="{ 'am-active': currentIndex == tabPanel }"
        class="am-tab-panel"
      >
        <ul class="solutions-content-ul">
          <li class="am-u-sm-12 am-u-md-6 am-u-lg-12">
            <div class="am-u-sm-12 am-u-md-12 am-u-lg-3 solution-tabs-img">
              <img src="../assets/ywjs1.png" />
            </div>
            <div class="am-u-sm-12 am-u-md-12 am-u-lg-9 solution-tabs-words">
              <h5>系统集成</h5>
              <p>
                系统集成业务是一种综合性的服务，它将不同的硬件、软件、网络和数据资源整合在一起，形成一个满足特定业务需求的整体解决方案。<br />
              </p>
            </div>
          </li>
          <div class="clear"></div>
        </ul>
      </div>
      <div
        v-if="tabPanel == 2"
        :class="{ 'am-active': currentIndex == tabPanel }"
        class="am-tab-panel"
      >
        <ul class="am-container-1 solutions-content-ul">
          <li class="am-u-sm-12 am-u-md-6 am-u-lg-12">
            <div class="am-u-sm-12 am-u-md-12 am-u-lg-3 solution-tabs-img">
              <img src="../assets/ywjs2.png" />
            </div>
            <div class="am-u-sm-12 am-u-md-12 am-u-lg-9 solution-tabs-words">
              <h5>AI 算力服务业务介绍</h5>
              <p>
                AI算力服务指的是为各类用户提供人工智能计算所需的硬件资源、软件环境及相关技术支持，以满足其在
                AI 模型训练、推理等应用场景下对计算能力的需求。随着 AI
                技术的飞速发展，模型复杂度和数据量不断攀升，对算力的要求也日益增长。许多企业、科研机构及开发者自身不具备构建和维护大规模算力基础设施的能力，AI
                算力服务应运而生，为他们提供了便捷、高效且经济的算力解决方案。<br />
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div
        v-if="tabPanel == 3"
        :class="{ 'am-active': currentIndex == tabPanel }"
        class="am-tab-panel"
      >
        <ul class="am-container-1 solutions-content-ul">
          <li class="am-u-sm-12 am-u-md-6 am-u-lg-12">
            <div class="am-u-sm-12 am-u-md-12 am-u-lg-3 solution-tabs-img">
              <img src="../assets/ywjs3.png" />
            </div>
            <div class="am-u-sm-12 am-u-md-12 am-u-lg-9 solution-tabs-words">
              <h5>人工智能应用软件开发</h5>
              <p>
                人工智能应用软件开发业务聚焦于运用人工智能技术，为不同行业和场景打造定制化的软件解决方案。通过整合机器学习、深度学习、自然语言处理、计算机视觉等人工智能技术，这类软件能够模拟人类智能，实现自动化决策、预测、识别等功能，助力企业和组织提升效率、优化流程、创新业务模式。<br />
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div
        v-if="tabPanel == 4"
        :class="{ 'am-active': currentIndex == tabPanel }"
        class="am-tab-panel"
      >
        <ul class="am-container-1 solutions-content-ul">
          <li class="am-u-sm-12 am-u-md-6 am-u-lg-12">
            <div class="am-u-sm-12 am-u-md-12 am-u-lg-3 solution-tabs-img">
              <img src="../assets/ywjs4.png" />
            </div>
            <div class="am-u-sm-12 am-u-md-12 am-u-lg-9 solution-tabs-words">
              <h5>计算机相关硬件销售</h5>
              <p>
                计算机相关硬件销售业务主要围绕各类计算机硬件产品展开，涵盖从个人电脑组件到企业级数据中心设备的广泛范畴。旨在为不同客户群体，包括个人消费者、中小企业、大型企业及科研机构等，提供满足其计算、存储、网络连接等需求的硬件产品及解决方案。通过与众多硬件制造商合作，确保提供多样化、高品质且价格合理的产品。
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div
        v-if="tabPanel == 5"
        :class="{ 'am-active': currentIndex == tabPanel }"
        class="am-tab-panel"
      >
        <ul class="am-container-1 solutions-content-ul">
          <li class="am-u-sm-12 am-u-md-6 am-u-lg-12">
            <div class="am-u-sm-12 am-u-md-12 am-u-lg-3 solution-tabs-img">
              <img src="../assets/ywjs5.png" />
            </div>
            <div class="am-u-sm-12 am-u-md-12 am-u-lg-9 solution-tabs-words">
              <h5>软件工程师驻场服务</h5>
              <p>
                软件工程师驻场服务是一种灵活的软件人力外包服务模式。在这种模式下，软件服务提供商根据客户的特定需求，选派专业的软件工程师到客户的工作场所进行现场办公。驻场工程师深度融入客户团队，与客户的员工紧密协作，共同完成软件项目的开发、维护、优化等工作任务，以满足客户在软件技术方面的短期或长期需求。
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
const tabList = ref([
  { name: "系统集成", id: 1 ,icon:'am-icon-desktop'},
  { name: "AI算力服务", id: 2 ,icon:'am-icon-mobile-phone mobile-phone'},
  { name: "人工智能应用软件开发", id: 3 ,icon:'am-icon-desktop'},
  { name: "计算机相关硬件销售", id: 4 ,icon:'am-icon-mobile-phone mobile-phone'},
  { name: "软件工程师驻场服务", id: 5 ,icon:'am-icon-desktop'},
]);
const tabPanel = ref(1);
const currentIndex = ref(1);
const tabChange = (val) => {
  currentIndex.value = val;
  switch (val) {
    case 1:
      tabPanel.value = 1;
      break;
    case 2:
      tabPanel.value = 2;
      break;
    case 3:
      tabPanel.value = 3;
      break;
    case 4:
      tabPanel.value = 4;
      break;
    case 5:
      tabPanel.value = 5;
      break;
  }
  console.log(tabPanel.value);
};
</script>