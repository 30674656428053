<template>
  <div class="part-all gray-li">
    <div class="customer am-container-server">
      <div class="part-title">
        <i class="am-icon-users part-title-i"></i>
        <span class="part-title-span">合作伙伴</span>
        <p>Cooperate Customers</p>
      </div>
      <div class="d-flex justify-center mt-20">
        <img
          v-for="(item, index) in imagesList"
          :key="index"
          :src="item"
          class="swiper-img"
        />
      </div>
      <!-- navigation  左右箭头 -->
      <!-- <swiper
        class="swiper-box"
        :slides-per-view="1"
        :space-between="0"
        @swiper="onSwiper"
        :modules="modules"
        :pagination="{ clickable: true }"
        :autoplay="{
          delay: 3000,
          disableOnInteraction: false,
        }"
        :loop="true"
      >
        <swiper-slide>
          <img
            v-for="(item, index) in imagesList"
            :key="index"
            :src="item"
            class="swiper-img"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            v-for="(item, index) in imagesList"
            :key="index"
            :src="item"
           class="swiper-img"
          />
        </swiper-slide>
      </swiper> -->
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, A11y, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import ptn1 from "../assets/ptn1.png";
import ptn2 from "../assets/ptn2.png";
import ptn3 from "../assets/ptn3.png";
import ptn4 from "../assets/ptn4.png";
import ptn5 from "../assets/ptn5.png";
import ptn6 from "../assets/ptn6.png";
import ptn7 from "../assets/ptn7.png";
import ptn8 from "../assets/ptn8.png";
import ptn9 from "../assets/ptn9.png";
import ptn10 from "../assets/ptn10.png";
const modules = [Navigation, Pagination, A11y, Autoplay];
const swiperInstance = ref(null);
const imagesList = ref([
  ptn1,
  ptn2,
  ptn3,
  ptn4,
  ptn5,
  ptn6,
  ptn7,
  ptn8,
  ptn9,
  ptn10,
]);
//初始化swiper后，为swiperInstance赋值
const onSwiper = (swiper) => {
  swiperInstance.value = swiper;
};
</script>
<style scoped>
.swiper-box {
  width: 100%;
  margin-top: 20px;
  text-align: center;
}
.swiper-img {
  height: 100px;
  margin: 0 10px;
  border-radius: 10px;
}
</style>