<template>
  <div class="footer">
    <ul>
      <li class="am-u-lg-4 am-u-md-4 am-u-sm-12 part-5-li2">
        <div class="part-5-title">联系我们</div>
        <div class="part-5-words2">
          <span
            >地址：北京市海淀区学清路甲18号中关村东升科技园学院园3层B341室</span
          >
          <span>系统集成部：13910858691</span>
          <span>技术服务/人力外包部：13681332896</span>
          <span
            >邮箱：<a href="mailto:jianyou@jianyoutech.com"
              >jianyou@jianyoutech.com</a
            ></span
          >
          <span><i class="am-icon-phone"></i><em>18601060333</em></span>
        </div>
      </li>
      <li class="am-u-lg-4 am-u-md-4 am-u-sm-12">
        <div class="part-5-title">相关链接</div>
        <div class="part-5-words2">
          <ul class="part-5-words2-ul d-flex">
            <li class="am-u-lg-4 am-u-md-6 am-u-sm-4">
              <router-link :to="{ path: '/Solutions', query: { id: 1 } }"
                >业务介绍</router-link
              >
            </li>
            <li class="am-u-lg-4 am-u-md-6 am-u-sm-4">
              <router-link :to="{ path: '/ProductShow', query: { id: 2 } }"
                >产品展示</router-link
              >
            </li>
            <li class="am-u-lg-4 am-u-md-6 am-u-sm-4">
              <router-link :to="{ path: '/CustomerCase', query: { id: 3 } }"
                >客户案例</router-link
              >
            </li>
            <!-- <li class="am-u-lg-4 am-u-md-6 am-u-sm-4">
              <router-link :to="{path:'/ServiceCenter',query:{id:4}}">服务中心</router-link>
            </li> -->
            <li class="am-u-lg-4 am-u-md-6 am-u-sm-4">
              <router-link :to="{ path: '/About', query: { id: 6 } }"
                >关于我们</router-link
              >
            </li>
            <!-- <router-link :to="{path:'/recruitPae',query:{id:7}}">招贤纳士</router-link> -->
            <div class="clear"></div>
          </ul>
        </div>
      </li>
      <li class="am-u-lg-8 am-u-md-8 am-u-sm-12 code-box">
        <img src="../assets/code.png" alt="" class="code-img" />
      </li>
      <div class="clear"></div>
    </ul>
    <div class="company-registration">
      ©{{
        currentYear
      }}
      北京中科建友科技股份有限公司&emsp;版权所有&emsp;京ICP备16051489号-1
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
const currentYear = ref(null);
currentYear.value = new Date().getFullYear();
console.log(currentYear.value);
</script>
<style  scoped>
.company-registration {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin: 10px 12px 0 36px;
  font-size: 13px;
}
a {
  color: #fff;
}
.code-box {
  display: flex;
  align-items: center;
  height: 140px;
}
.code-img {
  width: 100px;
  display: flex;
  align-items: center;
}
</style>