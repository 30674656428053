<template>
  <!-- 轮播图 -->
  <!-- navigation  左右点击按钮 -->
  <swiper
    class="swiper"
    :slides-per-view="1"
    :space-between="0"
    @swiper="onSwiper"
    :modules="modules"
    :pagination="{ clickable: true }"
    :autoplay="{
      delay: 1200,
      disableOnInteraction: false,
    }"
    :loop="true"
  >
    <swiper-slide>
      <img src="../assets/jy_bg1.png" class="rollpic-img" />
    </swiper-slide>
    <swiper-slide>
      <img src="../assets/jy_bg2.png" class="rollpic-img" />
    </swiper-slide>
  </swiper>
  <!-- 解决方案 -->
  <!-- <div class="am-container-1">
    <div class="solutions part-all">
      <div class="part-title">
        <router-link :to="{ path: '/Solutions', query: { id: 1 } }"
          ><i class="am-icon-lightbulb-o part-title-i"></i>
          <span class="part-title-span">解决方案</span>
          <p>Solutions</p></router-link
        >
      </div>
      <ul class="am-g part-content solutions-content">
        <li class="am-u-sm-6 am-u-md-3 am-u-lg-3">
          <i class="am-icon-safari solution-circle"></i>
          <span class="solutions-title">网站、移动网站</span>
          <p class="solutions-way">微信公众号开发移动网站微信公众号开发</p>
        </li>
        <li class="am-u-sm-6 am-u-md-3 am-u-lg-3">
          <i class="am-icon-magic solution-circle"></i>
          <span class="solutions-title">网站、移动网站</span>
          <p class="solutions-way">
            移动网站微信公众号开发移动网站微信公众号开发,解决方案
          </p>
        </li>
        <li class="am-u-sm-6 am-u-md-3 am-u-lg-3">
          <i class="am-icon-phone solution-circle"></i>
          <span class="solutions-title">网站、移动网站</span>
          <p class="solutions-way">
            移动网站微信公众号开发移动网站微信公众号开发
          </p>
        </li>
        <li class="am-u-sm-6 am-u-md-3 am-u-lg-3">
          <i class="am-icon-hacker-news solution-circle"></i>
          <span class="solutions-title">网站、移动网站</span>
          <p class="solutions-way">
            网站、移动网站微信公众号开发移动网站微信公众号开发,解决方案
          </p>
        </li>
      </ul>
    </div>
  </div> -->
  <!-- 客户案例 -->
  <div class="gray-li">
    <div class="customer-case part-all">
      <div class="part-title">
        <router-link :to="{ path: '/CustomerCase', query: { id: 3 } }">
          <i class="am-icon-briefcase part-title-i"></i>
          <span class="part-title-span">客户案例</span>
          <p>Customer Case</p></router-link
        >
      </div>

      <ul
        data-am-widget="gallery"
        class="am-avg-sm-1 am-avg-md-4 am-avg-lg-4 am-gallery-bordered customer-case-content"
      >
        <li class="case-li am-u-sm-6 am-u-md-6 am-u-lg-3">
          <div class="am-gallery-item case-img1">
            <img src="../assets/app1.png" />
          </div>
          <div class="case-li-mengban">
            <div class="case-word">
              <h3 class="am-gallery-title">中检实业简易生活项目</h3>
              <!-- <p>2015-06-11</p> -->
              <router-link :to="{ path: '/CustomerCase', query: { id: 3 } }"
              ><span><i class="am-icon-eye"></i>查看更多</span></router-link>
            </div>
          </div>
        </li>
        <li class="case-li am-u-sm-6 am-u-md-6 am-u-lg-3">
          <div class="am-gallery-item case-img1">
            <img src="../assets/app2.png" />
          </div>
          <div class="case-li-mengban">
            <div class="case-word">
              <h3 class="am-gallery-title">
                中海油服市场管理信息化平台建设项目
              </h3>
              <!-- <p>2017-09-20</p> -->
              <router-link :to="{ path: '/CustomerCase', query: { id: 3 } }"
              ><span><i class="am-icon-eye"></i>查看更多</span></router-link>
            </div>
          </div>
        </li>
        <li class="case-li am-u-sm-6 am-u-md-6 am-u-lg-3">
          <div class="am-gallery-item case-img1">
            <img src="../assets/app3.png" />
          </div>
          <div class="case-li-mengban">
            <div class="case-word">
              <h3 class="am-gallery-title">
                道路交通安全治理一体化运作实战平台
              </h3>
              <!-- <p>2018-10-01</p> -->
              <router-link :to="{ path: '/CustomerCase', query: { id: 3 } }"
              ><span><i class="am-icon-eye"></i>查看更多</span></router-link>
            </div>
          </div>
        </li>
        <li class="case-li am-u-sm-6 am-u-md-6 am-u-lg-3">
          <div class="am-gallery-item case-img1">
            <img src="../assets/app4.png"/>
          </div>
          <div class="case-li-mengban">
            <div class="case-word">
              <h3 class="am-gallery-title">AI算力服务</h3>
              <!-- <p>2015-06-11</p> -->
              <router-link :to="{ path: '/CustomerCase', query: { id: 3 } }"
                ><span><i class="am-icon-eye"></i>查看更多</span></router-link>
            </div>
          </div>
        </li>
      </ul>
      <div class="lan-bott">
        <div class="left">
          <span>全方位解决方案,为您轻松解决不同问题</span>
          <p>A full range of solutions for you to solve different problems</p>
        </div>
        <div class="right">
          <router-link :to="{ path: '/CustomerCase', query: { id: 3 } }">
            <span class="see-more"
              >查看更多<i class="am-icon-angle-double-right"></i></span
          ></router-link>
        </div>
        <div class="clear"></div>
      </div>
      <div class="part-title"></div>
    </div>
  </div>
  <!-- 新闻动态 -->
  <div class="news-all">
    <div class="am-container-1">
      <div class="news part-all">
        <div class="part-title">
          <router-link :to="{ path: '/newsInformation', query: { id: 5 } }">
            <i class="am-icon-newspaper-o part-title-i"></i>
            <span class="part-title-span">新闻动态</span>
            <p>Jianyou News</p></router-link
          >
        </div>
        <div class="news-content">
          <ul class="news-content-ul">
            <li class="am-u-sm-12 am-u-md-6 am-u-lg-6">
              <div class="am-u-sm-12 am-u-md-12 am-u-lg-5">
                <div class="news-img">
                  <img src="../assets/news.png" />
                </div>
              </div>
              <div class="am-u-sm-12 am-u-md-12 am-u-lg-7">
                <span class="news-right-title">子公司设立</span>
                <p class="news-right-time">2024年12月17日</p>
                <p class="news-right-words">
                  中科建友全资子公司新疆建友人工智能有限公司成立，注册资金2亿元人民币，公司发展算力服务领域。
                </p>
                <!-- <a
                    ><span class="see-more2"
                      >查看更多<i class="am-icon-angle-double-right"></i></span
                  ></a> -->
              </div>
              <div class="clear"></div>
            </li>
            <li class="am-u-sm-12 am-u-md-6 am-u-lg-6">
              <div class="am-u-sm-12 am-u-md-12 am-u-lg-5">
                <div class="news-img">
                  <img src="../assets/news1.png" />
                </div>
              </div>
              <div class="am-u-sm-12 am-u-md-12 am-u-lg-7">
                <span class="news-right-title">ISO体系认证</span>
                <p class="news-right-time">2024年12月23日</p>
                <p class="news-right-words">
                  中科建友通过ISO/IEC 20000信息技术服务管理体系认证和ISO/IEC
                  27001信息安全管理体系认证。
                </p>
                <!-- <a
                    ><span class="see-more2"
                      >查看更多<i class="am-icon-angle-double-right"></i></span
                  ></a> -->
              </div>
              <div class="clear"></div>
            </li>
            <div class="clear"></div>
          </ul>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- 服务客户 -->
  <serve-customers></serve-customers>
</template>
<script setup>
import { ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, A11y, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import ServeCustomers from "../components/serveCustomers.vue";
const modules = [Navigation, Pagination, A11y, Autoplay];
const swiperInstance = ref(null);
//初始化swiper后，为swiperInstance赋值
const onSwiper = (swiper) => {
  swiperInstance.value = swiper;
};
</script>
<style scoped>
.rollpic-img {
  width: 100%;
  height: auto;
}
.swiper{
  margin-top: 80px;
}
</style>
