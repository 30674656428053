<template>
  <div class="am-topbar header">
    <div class="nav-header">
      <div class="left hw-logo left-logo">
        <img class="logo" src="../assets/jianyou.png" />
        <img class="word" src="../assets/jy-word.png" />
      </div>
      <div class="d-flex align-center">
        <span
          v-for="(item, index) in tabList"
          :key="index"
          :class="{ 'hw-menu-active': item.id == currentIndex }"
          @click="tabClick(item)"
          class="nav-item"
        >
          {{ item.name }}
        </span>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
const currentIndex = ref(0);
const router = useRouter();
const route = useRoute();
const tabList = ref([
  { name: "首页", path: "/", id: 0 },
  { name: "业务介绍", path: "/Solutions", id: 1 },
  { name: "产品展示", path: "/ProductShow", id: 2 },
  { name: "客户案例", path: "/CustomerCase", id: 3 },
  // { name: "服务中心", path: "/ServiceCenter", id: 4 },
  // {name:'新闻动态',path:'/newsInformation',id:5},
  { name: "关于我们", path: "/About", id: 6 },
  // {name:'招贤纳士',path:'/recruitPae',id:7},
]);
const tabClick = (item) => {
  currentIndex.value = item.id;
  router.push({ path: item.path, query: { id: item.id } });
};
watch(
  () => route.query,
  (newVal) => {
    if (newVal) {
      currentIndex.value = newVal.id;
    }
  }
);
</script>
