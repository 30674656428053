<template>
  <div class="toppic">
    <div class="am-container-1">
      <div class="toppic-title left">
        <i class="am-icon-paper-plane toppic-title-i"></i>
        <span class="toppic-title-span">关于我们</span>
        <p>About Us</p>
      </div>
      <div class="right toppic-progress">
        <span
          ><router-link :to="{ path: '/', query: { id: 0 } }" class="w-white"
            >首页</router-link
          ></span
        >
        <i class="am-icon-arrow-circle-right w-white"></i>
        <span
          ><router-link
            :to="{ path: '/About', query: { id: 6 } }"
            class="w-white"
            >关于我们</router-link
          ></span
        >
      </div>
    </div>
  </div>
  <!-- 公司简介 -->
  <div class="am-container-1">
    <div class="part-title part-title-mar">
      <router-link :to="{ path: '/CustomerCase', query: { id: 3 } }">
        <i class="am-icon-paper-plane part-title-i"></i>
        <span class="part-title-span">关于中科建友</span>
        <p>About Zhongkejianyou</p>
      </router-link>
    </div>
    <div class="company-intro">
      <p>
        &emsp;&emsp;北京中科建友科技股份有限公司（简称：“中科建友”）成立于2009年9月29日，注册资金3891.375万元，公司位于北京市海淀区。中科建友面向软件技术服务领域,致力于为客户提供高质量软件技术外包服务和软件项目开发服务，是专业的软件和技术服务提供商。
      </p>
      <p>
        &emsp;&emsp;中科建友主要业务有：系统集成、AI算力服务、人工智能应用软件开发、计算机相关硬件销售、软件工程师驻场服务等业务。
      </p>
      <p>
        &emsp;&emsp;中科建友以“专业的技术服务提供商”为发展目标，为国内大中型企事业单位提供高质量的设计、开发及技术人才派遣服务。公司深耕电网、金融、电信、能源、航天、互联网等客户并提供专业、全面、高效的软件技术服务。
      </p>
      <p>中科建友资质情况：</p>
      <p>1.国家高新技术企业证书</p>
      <p>2.中关村高新技术企业证书</p>
      <p>3.ISO27001信息安全管理体系认证证书</p>
      <p>4.ISO20000信息技术服务管理体系认证证书</p>
      <p>5.ISO14001环境管理体系认证证书</p>
      <p>6.ISO45001职业健康安全管理体系认证证书</p>
      <p>7.ISO9001质量管理体系认证证书</p>
      <p>8.北京市“专精特新”中小企业证书</p>
      <p>9.北京市“创新型”中小企业证书</p>
      <p>10.I类发明专利：基于人工智能的交通大数据预测系统</p>
      <p>11.II类实用新型专利7项</p>
      <p>12.II类软件著作权45项</p>
      <p>13.3项商标注册</p>
      <p>
        企业愿景：秉承诚信和负责的态度，去迎接无限的胜利，成为一个让客户信任的公司
      </p>
      <p>企业使命：提供优质的软件技术解决方案，为客户创造价值</p>
      <p>企业核心价值观：用心、创新、诚信</p>
      <p>企业作风：务实创新、精益求精</p>
    </div>
  </div>
  <div class="gray-li company-thought-all">
    <div class="am-container-1">
      <ul class="company-thought">
        <li>
          <div class="thought-all">
            <i class="am-icon-circle-o-notch"></i>
            <span>企业理念</span>
            <div class="thought-all-none">
              <h5>专注 专业</h5>
            </div>
          </div>
        </li>
        <li>
          <div class="thought-all">
            <i class="am-icon-hand-o-right"></i>
            <span>服务理念</span>
            <div class="thought-all-none">
              <h5>实务 用心</h5>
            </div>
          </div>
        </li>
        <li>
          <div class="thought-all">
            <i class="am-icon-bar-chart"></i>
            <span>发展方向</span>
            <div class="thought-all-none">
              <h5>精益求精</h5>
            </div>
          </div>
        </li>
        <div class="clear"></div>
      </ul>
    </div>
  </div>
  <!-- 办公环境 -->
  <div class="am-container-1">
    <div class="part-title part-title-mar">
      <router-link :to="{ path: '/CustomerCase', query: { id: 3 } }">
        <i class="am-icon-home part-title-i"></i>
        <span class="part-title-span">办公环境</span>
        <p>Office Space</p>
      </router-link>
    </div>
    <div class="office-space">
      <div class="cam-u-lg-3 am-u-md-3 am-u-sm-6">
        <img src="../assets/space-1.png" />
      </div>
      <div class="cam-u-lg-3 am-u-md-3 am-u-sm-6">
        <img src="../assets/space-2.png" />
      </div>
      <div class="cam-u-lg-3 am-u-md-3 am-u-sm-6">
        <img src="../assets/space-3.png" />
      </div>
      <div class="cam-u-lg-3 am-u-md-3 am-u-sm-6">
        <img src="../assets/space-4.png" />
      </div>
      <div class="clear"></div>
    </div>
  </div>
  <!-- 联系我们 -->
  <div class="am-container-1">
    <div class="part-title part-title-mar">
      <router-link :to="{ path: '/CustomerCase', query: { id: 3 } }">
        <i class="am-icon-comments-o part-title-i"></i>
        <span class="part-title-span">联系我们</span>
        <p>Contact Us</p>
      </router-link>
    </div>
  </div>
  <div class="gray-li">
    <div class="am-container-1">
      <div class="contact-us">
        <div class="am-u-lg-6 am-u-md-6 am-u-sm-12">
          <img src="../assets/map.png" />
        </div>
        <div class="am-u-lg-6 am-u-md-6 am-u-sm-12">
          <ul class="contact-add">
            <li>
              <div>
                <i class="am-icon-map-marker"></i
                ><span class="contact-add-1"
                  >北京市海淀区学清路甲18号中关村东升科技园学院园3层B341室</span
                >
              </div>
            </li>
            <li>
              <div>
                <i class="am-icon-phone"></i
                ><span>系统集成部：13910858691</span>
              </div>
            </li>
            <li>
              <div>
                <i class="am-icon-mobile mobile"></i
                ><span>技术服务/人力外包部：13681332896</span>
              </div>
            </li>
            <li>
              <div>
                <i class="am-icon-envelope-o"></i
                ><span><a href="mailto:jianyou@jianyoutech.com">jianyou@jianyoutech.com</a></span>
              </div>
            </li>
          </ul>
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>
<script setup>
</script>
