
import { createRouter, createWebHistory } from 'vue-router';

// 引入Vue组件
import Home from '../views/homePage.vue'; // 首页
import Solutions from '../views/solutionsPage.vue'; // 业务介绍
import ProductShow from '../views/productShow.vue'; // 产品展示
import CustomerCase from '../views/customerCase.vue'; // 客户案例
import ServiceCenter from '../views/serviceCenter.vue'; // 服务中心
import About from '../views/aboutPage.vue'; // 关于我们
import newsDetails from '../views/newsDetails.vue'; // 新闻详情
import newsInformation from '../views/newsInformation.vue'; // 新闻动态 
import recruitPae from '../views/recruitPae.vue';// 招贤纳士

// 定义路由
const routes = [
    { path: '/', name: 'Home', component: Home },
    { path: '/Solutions', name: 'Solutions', component: Solutions },
    { path: '/ProductShow', name: 'ProductShow', component: ProductShow },
    { path: '/CustomerCase', name: 'CustomerCase', component: CustomerCase },
    { path: '/ServiceCenter', name: 'ServiceCenter', component: ServiceCenter },
    { path: '/About', name: 'About', component: About },
    { path: '/newsDetails', name: 'newsDetails', component: newsDetails },
    { path: '/newsInformation', name: 'newsInformation', component: newsInformation },
    { path: '/recruitPae', name: 'recruitPae', component: recruitPae },
];

// 创建router实例
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // 滚动到顶部
        return { top: 0 }
    }
});
export default router;