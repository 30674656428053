<template>
  <div class="toppic">
    <div class="am-container-1">
      <div class="toppic-title left">
        <i class="am-icon-dropbox toppic-title-i"></i>
        <span class="toppic-title-span">产品展示</span>
        <p>Product Show</p>
      </div>
      <div class="right toppic-progress">
        <span
          ><router-link :to="{ path: '/', query: { id: 0 } }" class="w-white"
            >首页</router-link
          ></span
        >
        <i class="am-icon-arrow-circle-right w-white"></i>
        <span
          ><router-link
            :to="{ path: '/ProductShow', query: { id: 2 } }"
            class="w-white"
            >产品展示</router-link
          ></span
        >
      </div>
    </div>
  </div>

  <div>
    <ul class="product-show-ul">
      <li>
        <div class="product-content">
          <div class="left am-u-sm-12 am-u-md-6 am-u-lg-6 product-content-left">
            <div class="product-show-title">
              <h3>基于人工智能的交通大数据预测系统</h3>
              <span>发明专利</span>
            </div>
            <div class="product-show-content">
              <!-- <div class="product-add">
                <span>查看地址：</span>
                <div><a href="#">http://www.hwshop.com</a></div>
                <i class="am-icon-dribbble"></i>
              </div> -->
              <div class="product-intro">
                <span>详情介绍：</span>
                  <p>
                    基于人工智能的交通大数据预测系统，包括：预测执行设备，用于基于智能预测体采用设定铁路车次的各项行程数据以及设定铁路车次在当天之前设定数量的各天分别对应的各份乘车信息智能预测设定铁路车次在当天的始发站的乘客数量的预测值；动态控制设备，用于基于智能预测的预测值确定设定铁路车次需要配置的车厢数目。本发明的基于人工智能的交通大数据预测设计智能、操控简便。由于能够针对设定铁路车次采用人工智能模式准确预测每天始发站的出发乘客数量，进而动态确定相应的始发站车厢分配数量，从而有效调节了始发站车厢数量有限以及始发站出发的乘客数量随机动态变化之间的矛盾，兼顾了运力充足以及资源节省。
                  </p>
                <i class="am-icon-tasks"></i>
              </div>
            </div>
          </div>
          <div
            class="right am-u-sm-12 am-u-md-6 am-u-lg-6 product-content-right"
          >
            <img class="product-img" src="../assets/product1.png" />
          </div>
          <div class="clear"></div>
        </div>
      </li>
      <li class="gray-li">
        <div class="product-content">
          <div class="left am-u-sm-12 am-u-md-6 am-u-lg-6 product-content-left">
            <img class="product-img" src="../assets/product2.png" />
          </div>
          <div
            class="right am-u-sm-12 am-u-md-6 am-u-lg-6 product-content-right"
          >
            <div class="product-show-title">
              <h3>一种有利于夜间插接的插头组件</h3>
              <span>实用新型专利</span>
            </div>

            <div class="product-show-content">
              <!-- <div class="product-add">
                <span>查看地址：</span>
                <div><a href="#">http://www.hwshop.com</a></div>
                <i class="am-icon-dribbble"></i>
              </div> -->
              <div class="product-intro">
                <span>详情介绍：</span>
                <div>
                  <p>
                    一种有利于夜间插接的插头组件，包括插头、插座，插头上设有收纳槽且收纳槽设于插接头的外侧，收纳槽内设有配合插头导向的导向件，插座上设有与插接头配合插接的插孔，插座上设有对导向件承接的导向槽，插头在与插座插接时，导向件与导向槽配合磁吸导向，使得导向件和导向槽配合对接后，位于导向件和导向槽内侧的插接头和插孔相对应，对插头向插座位置移动，随着插头向插座的使力导向件收纳在收纳槽中，插头上的插接头插接在插座上的插孔中的同时，插头上的限位件对导向件的末端限定，对导向件固定在收纳槽中，达到对插头与插座在夜间插接使用方便性的同时保持插头与插座插接稳定的目的。
                  </p>
                </div>
                <i class="am-icon-tasks"></i>
              </div>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </li>
      <li>
        <div class="product-content">
          <div class="left am-u-sm-12 am-u-md-6 am-u-lg-6 product-content-left">
            <div class="product-show-title">
              <h3>一种可改变使用长度的车载数据线</h3>
              <span>实用新型专利</span>
            </div>

            <div class="product-show-content">
              <!-- <div class="product-add">
                <span>查看地址：</span>
                <div><a href="#">http://www.hwshop.com</a></div>
                <i class="am-icon-dribbble"></i>
              </div> -->
              <div class="product-intro">
                <span>详情介绍：</span>
                <div>
                  <p>
                    提供一种可改变使用长度的车载数据线，涉及车载数据线技术领域，包括卷线盒，卷线盒上端面设有密封盖，卷线盒内部固定有转轴，转轴外壁转动连接有收线器，收线器底端面固定有转动弹簧，转动弹簧另一端面与卷线盒内壁固定连接，收线器上端面设有卡齿，密封盖上端面固定有固定器，固定器内壁固定有缓冲弹簧，缓冲弹簧上端面固定有垫板，垫板上端面固定有活动立柱，垫板另一端固定有固定卡销，固定器内壁固定有支撑柱，通过将数据线与收线器连接，将数据线进行自动缠绕在收线器上，从而实现避免因为乘客充完电乱放导致数据线发生损坏，对车载数据线进行保护。
                  </p>
                </div>
                <i class="am-icon-tasks"></i>
              </div>
            </div>
          </div>
          <div
            class="right am-u-sm-12 am-u-md-6 am-u-lg-6 product-content-right"
          >
            <img class="product-img" src="../assets/product3.png" />
          </div>
          <div class="clear"></div>
        </div>
      </li>
      <li class="gray-li">
        <div class="product-content">
          <div class="left am-u-sm-12 am-u-md-6 am-u-lg-6 product-content-left">
            <img class="product-img" src="../assets/product4.png" />
          </div>
          <div
            class="right am-u-sm-12 am-u-md-6 am-u-lg-6 product-content-right"
          >
            <div class="product-show-title">
              <h3>一种电子产品销售用电子产品放置架</h3>
              <span>实用新型专利</span>
            </div>

            <div class="product-show-content">
              <!-- <div class="product-add">
                <span>查看地址：</span>
                <div><a href="#">http://www.hwshop.com</a></div>
                <i class="am-icon-dribbble"></i>
              </div> -->
              <div class="product-intro">
                <span>详情介绍：</span>
                <div>
                  <p>
                    属于产品放置架技术领域，具体的说是一种电子产品销售用电子产品放置架，包括第一支撑管；所述第一支撑管内侧壁转动连接有若干个第一支撑柱；所述第一支撑柱外侧壁固接有第一支撑板；所述第一支撑柱外侧壁固接有转盘；所述转盘内侧壁滑动接触有一对限位柱；所述限位柱外侧壁固接有第一连接板；所述第一连接板外侧壁固接有第一弹簧；所述第一弹簧外侧壁固接有第一支撑管；所述第一连接板外侧壁固接有第一连接柱；通过设置的转盘与限位柱，降低在对电子产品展示时，因电子产品垂直朝上，导致客户无法直观了解电子产品顶部的情况，减少客户在想要观察设备顶部时需要搬动设备，或者将头部伸入电子产品的动作，便于对电子产品的展示。
                  </p>
                </div>
                <i class="am-icon-tasks"></i>
              </div>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </li>
      <div class="clear"></div>
    </ul>
  </div>
</template>
<script setup>
</script>