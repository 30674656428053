<template>
  <div class="toppic">
    <div class="am-container-1">
      <div class="toppic-title left">
        <i class="am-icon-newspaper-o toppic-title-i"></i>
        <span class="toppic-title-span">新闻详情</span>
        <p>News Information</p>
      </div>
      <div class="right toppic-progress">
        <span><router-link :to="{ path: '/newsInformation', query: { id: 5 } }">新闻动态</router-link></span>
        <i class="am-icon-arrow-circle-right w-white"></i>
        <span><router-link to="/newsDetails">新闻详情</router-link></span>
      </div>
    </div>
  </div>
  <div class="am-container-1 margin-t30">
    <div class="words-title">
      <span>关于召开2015全国互联网工作年会的通知</span>
      <div>2015-12-29</div>
    </div>
  </div>

  <div class="solution-inform">
    <div class="solution-inform-content-all">
      <div class="solution-inform-content">
        <p class="inform-content-p">
          华天公路货运管理系统是华天软件为物流货运企业全力打造的一套物流网络信息化的实在营运解决方案，通过６年的不断积累，汲取数十家物流企业的实战经验，以客户为中心，以业务为纽带，为车辆调度，仓库管理，装车发货，分拣中心，卸货提货，
        </p>
        <div class="solution-inform-content-img">
          <img src="../assets/inform.png" />
          <div class="clear"></div>
        </div>
        <p class="solution-inform-content-words">
          (一) 业务管理预受理单据 1)
          预受理单据用于需要上门取货的客户及货物信息的录入。预受理单据可以由发货客户远程录入，也可以由业务员录入。录入的预受理单据信息主要包括以下内容：
          2) 发货人信息；收货人信息；终点站、到站、付款方式是否保险（保价费）及
          3) 货物信息：货物名称、货物包装、件数、运费。 4)
          预受理单支持多种条件组合查询。
        </p>

        <p class="solution-inform-content-words">
          醒信息后，可以点击进入进行预受理派车操作（录入车号、默认司机信息（姓名、电话），提交派车）。
          2)
          也可以通过查询客户名称、可以通过日期实在营运解决方案，通过６年的不断积累，汲取数十家物流企业的实战经验，以客户为中心，以业务为纽带，为车辆调度，仓库管理，装车发货，分拣中心，卸货提货
        </p>

        <p class="solution-inform-content-words">
          (一) 业务管理预受理单据 1)
          预受理单据用于需要上门取货的客户及货物信息的录入。预受理单据可以由发货客户远程录入，也可以由业务员录入。录入的预受理单据信息主要包括以下内容：
          2) 发货人信息；收货人信息；终点站、到站、付款方式是否保险（保价费）及
          3) 货物信息：货物名称、货物包装、件数、运费。 4)
          预受理单支持多种条件组合查询。
        </p>

        <p class="solution-inform-content-words">
          醒信息后，可以点击进入进行预受理派车操作（录入车号、默认司机信息（姓名、电话），提交派车）。
          2)
          也可以通过查询客户名称、可以通过日期实在营运解决方案，通过６年的不断积累，汲取数十家物流企业的实战经验，以客户为中心，以业务为纽带，为车辆调度，仓库管理，装车发货，分拣中心，卸货提货
        </p>
      </div>
    </div>
  </div>
</template>
