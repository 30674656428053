<template>
  <div class="toppic">
    <div class="am-container-1">
      <div class="toppic-title left">
        <i class="am-icon-newspaper-o toppic-title-i"></i>
        <span class="toppic-title-span">新闻动态</span>
        <p>jianyou News</p>
      </div>
      <div class="right toppic-progress">
        <span><router-link :to="{ path: '/', query: { id: 0 } }" class="w-white">首页</router-link></span>
        <i class="am-icon-arrow-circle-right w-white"></i>
        <span
          ><router-link :to="{ path: '/newsInformation', query: { id: 5 } }" class="w-white"
            >新闻动态</router-link
          ></span
        >
      </div>
    </div>
  </div>

  <div class="am-container-1 news-content-all">
    <div class="left am-u-sm-12 am-u-md-8 am-u-lg-9">
      <ul class="news-ul">
        <li class="am-u-sm-12 am-u-md-6 am-u-lg-4">
          <router-link to="/newsDetails">
            <div class="news-ul-liall">
              <img class="news-ul-liimg" src="../assets/news.png" />
              <div class="inform-list">
                <div class="inform-list-date">
                  <i class="am-icon-arrow-circle-right"></i>2015-6-11
                </div>
                <div class="inform-list-label">
                  <i class="am-icon-arrow-circle-right"></i>互联网 开发
                </div>
                <div class="inform-list-numb">
                  <i class="am-icon-arrow-circle-right"></i>点击次数：273
                </div>
              </div>
              <span>关于召开年会通知</span>
              <p>
                互联网，又称网际网路或音网际网路或音译因特网英特网，是网络与网络之间所串连成的庞大网络网络与网络之间大家可是快乐的
              </p>
              <span class="see-more3"
                >查看更多<i class="am-icon-angle-double-right"></i
              ></span>
            </div>
          </router-link>
        </li>
        <li class="am-u-sm-12 am-u-md-6 am-u-lg-4">
          <div class="news-ul-liall">
            <img class="news-ul-liimg" src="../assets/news1.png" />
            <div class="inform-list">
              <div class="inform-list-date">
                <i class="am-icon-arrow-circle-right"></i>2015-6-11
              </div>
              <div class="inform-list-label">
                <i class="am-icon-arrow-circle-right"></i>互联网 开发
              </div>
              <div class="inform-list-numb">
                <i class="am-icon-arrow-circle-right"></i>点击次数：273
              </div>
            </div>
            <span>关于召开年会通知</span>
            <p>
              互联网，又称网际网路或音网际网路或音译因特网英特网，是网络与网络之间所串连成的庞大网络网络与网络之间大家可是快乐的
            </p>
            <span class="see-more3"
              >查看更多<i class="am-icon-angle-double-right"></i
            ></span>
          </div>
        </li>
        <li class="am-u-sm-12 am-u-md-6 am-u-lg-4">
          <div class="news-ul-liall">
            <img class="news-ul-liimg" src="../assets/news2.png" />
            <div class="inform-list">
              <div class="inform-list-date">
                <i class="am-icon-arrow-circle-right"></i>2015-6-11
              </div>
              <div class="inform-list-label">
                <i class="am-icon-arrow-circle-right"></i>互联网 开发
              </div>
              <div class="inform-list-numb">
                <i class="am-icon-arrow-circle-right"></i>点击次数：273
              </div>
            </div>
            <span>关于召开年会通知</span>
            <p>
              互联网，又称网际网路或音网际网路或音译因特网英特网，是网络与网络之间所串连成的庞大网络网络与网络之间大家可是快乐的
            </p>
            <span class="see-more3"
              >查看更多<i class="am-icon-angle-double-right"></i
            ></span>
          </div>
        </li>
        <li class="am-u-sm-12 am-u-md-6 am-u-lg-4">
          <div class="news-ul-liall">
            <img class="news-ul-liimg" src="../assets/news.png" />
            <div class="inform-list">
              <div class="inform-list-date">
                <i class="am-icon-arrow-circle-right"></i>2015-6-11
              </div>
              <div class="inform-list-label">
                <i class="am-icon-arrow-circle-right"></i>互联网 开发
              </div>
              <div class="inform-list-numb">
                <i class="am-icon-arrow-circle-right"></i>点击次数：273
              </div>
            </div>
            <span>关于召开年会通知</span>
            <p>
              互联网，又称网际网路或音网际网路或音译因特网英特网，是网络与网络之间所串连成的庞大网络网络与网络之间大家可是快乐的
            </p>
            <span class="see-more3"
              >查看更多<i class="am-icon-angle-double-right"></i
            ></span>
          </div>
        </li>
        <li class="am-u-sm-12 am-u-md-6 am-u-lg-4">
          <div class="news-ul-liall">
            <img class="news-ul-liimg" src="../assets/news1.png" />
            <div class="inform-list">
              <div class="inform-list-date">
                <i class="am-icon-arrow-circle-right"></i>2015-6-11
              </div>
              <div class="inform-list-label">
                <i class="am-icon-arrow-circle-right"></i>互联网 开发
              </div>
              <div class="inform-list-numb">
                <i class="am-icon-arrow-circle-right"></i>点击次数：273
              </div>
            </div>
            <span>关于召开年会通知</span>
            <p>
              互联网，又称网际网路或音网际网路或音译因特网英特网，是网络与网络之间所串连成的庞大网络网络与网络之间大家可是快乐的
            </p>
            <span class="see-more3"
              >查看更多<i class="am-icon-angle-double-right"></i
            ></span>
          </div>
        </li>
        <li class="am-u-sm-12 am-u-md-6 am-u-lg-4">
          <div class="news-ul-liall">
            <img class="news-ul-liimg" src="../assets/news2.png" />
            <div class="inform-list">
              <div class="inform-list-date">
                <i class="am-icon-arrow-circle-right"></i>2015-6-11
              </div>
              <div class="inform-list-label">
                <i class="am-icon-arrow-circle-right"></i>互联网 开发
              </div>
              <div class="inform-list-numb">
                <i class="am-icon-arrow-circle-right"></i>点击次数：273
              </div>
            </div>
            <span>关于召开年会通知</span>
            <p>
              互联网，又称网际网路或音网际网路或音译因特网英特网，是网络与网络之间所串连成的庞大网络网络与网络之间大家可是快乐的
            </p>
            <span class="see-more3"
              >查看更多<i class="am-icon-angle-double-right"></i
            ></span>
          </div>
        </li>
        <div class="clear"></div>
      </ul>
      <ul class="am-pagination">
        <li><a href="#">&laquo;</a></li>
        <li><a href="#">上一页</a></li>
        <li><a class="current-page">1</a></li>
        <li><a href="#">下一页</a></li>
        <li><a href="#">&raquo;</a></li>
      </ul>
    </div>

    <div class="left am-u-sm-12 am-u-md-4 am-u-lg-3">
      <section
        data-am-widget="accordion"
        class="am-accordion am-accordion-gapped"
        data-am-accordion="{  }"
      >
        <div class="hot-title">
          <i class="am-icon-thumbs-o-up"></i>热门新闻 / Hot News
        </div>
        <dl class="am-accordion-item am-active">
          <dt class="am-accordion-title">用户体验制作当中的一些可视化信息</dt>
          <dd class="am-accordion-bd am-collapse am-in">
            <div class="am-accordion-content">
              英特网，是网络与网络之间所串连成的庞大网络网又称网际网路或音译因特网、英特网，是网络与网络之间所串连成的庞大网络网络与网络之
            </div>
          </dd>
        </dl>
        <dl class="am-accordion-item">
          <dt class="am-accordion-title">可视化信息</dt>
          <dd class="am-accordion-bd am-collapse">
            <div class="am-accordion-content">
              英特网，是网络与网络之间所串连成的庞大网络网所串连成的庞大网络网
            </div>
          </dd>
        </dl>
        <dl class="am-accordion-item">
          <dt class="am-accordion-title">响应式购物商城</dt>
          <dd class="am-accordion-bd am-collapse">
            <div class="am-accordion-content">
              英特网，是网络与网络之间所串连成的庞大网络网所串连成的庞大网络网
            </div>
          </dd>
        </dl>
        <dl class="am-accordion-item">
          <dt class="am-accordion-title">可视化信息</dt>
          <dd class="am-accordion-bd am-collapse">
            <div class="am-accordion-content">
              英特网，是网络与网络之间所串连成的庞大网络网所串连成的庞大网络网
            </div>
          </dd>
        </dl>
        <dl class="am-accordion-item">
          <dt class="am-accordion-title">响应式购物商城</dt>
          <dd class="am-accordion-bd am-collapse">
            <div class="am-accordion-content">
              英特网，是网络与网络之间所串连成的庞大网络网所串连成的庞大网络网
            </div>
          </dd>
        </dl>
      </section>
    </div>

    <div class="clear"></div>
  </div>
</template>